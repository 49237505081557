<template>
  <div class="buyCard" @click="closeXSGoods()">
    <div class="main-card">
      <div class="tab">
        <div class="tab-head">
          <div style="width: 5%">
            <!-- 选择1 -->

            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
              :disabled="data && data.length == 0"></el-checkbox>
          </div>
          <div style="width: 38%; text-align: left">商品</div>
          <div style="width: 15%">单价</div>
          <div style="width: 14%">数量</div>
          <div style="width: 13%">总计</div>
          <div style="width: 13%">操作</div>
        </div>
        <div class="tab-list" v-if="data && data.length != 0">
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <div class="item" v-for="(item, i) in data" :key="i">
              <div class="title">
                <div style="padding: 0 10px">
                  <!-- 选择2 -->
                  <el-checkbox :indeterminate="item.isIndeterminate" :label="item.title"
                    @change="handleCheckAllChanges($event, item)">{{ item.title }}</el-checkbox>
                </div>
              </div>
              <!-- 选择3 -->
              <el-checkbox-group v-model="item.xzList">
                <div class="content" v-for="(val, index) in item.goods_list" :key="index">
                  <div style="width: 5%" class="item">
                    <el-checkbox @change="handleChange($event, item, val)" :label="val.id">&ensp;</el-checkbox>
                  </div>

                  <div style="width: 38%; text-align: left; display: flex">
                    <el-image style="width: 80px; height: 80px; min-width: 80px" :src="val.image" fit="fit"></el-image>
                    <div style="
                        padding: 6px 10px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 44px;
                        width: 220px;
                      ">
                      {{ val.title }}
                    </div>
                    <div style="color: #777777; display: flex; align-items: center"
                      v-if="val.spu && val.spu.length != 0">
                      <span>
                        <div>
                          规格：
                          <el-popover placement="bottom" width="400" trigger="click" v-model="val.pop"
                            @show="asds(val)">
                            <div class="guge" style="padding: 10px">
                              <div v-for="value in val.spu" :key="value.id">
                                <div style="margin-bottom: 10px">
                                  {{ value.name }}:
                                </div>
                                <div class="guge-list" style="display: flex; flex-wrap: wrap">
                                  <div style="
                                      padding: 6px 20px;
                                      border: 1px solid rgba(0, 0, 0, 0.09);
                                      border-radius: 2px;
                                      font-size: 14px;
                                      margin-bottom: 8px;
                                      margin-right: 10px;
                                      cursor: pointer;
                                      overflow: hidden;
                                      position: relative;
                                    " v-for="(eachs, index) in value.item" :key="index" @click="qiehuan(value, index)">
                                    {{ eachs }}
                                    <div class="tick" style="
                                        border: 15px solid transparent;
                                        border-bottom: 15px solid #ee4d2d;
                                        position: absolute;
                                        right: -15px;
                                        bottom: 0;
                                      " v-if="value.spIndex == index">
                                      <i class="el-icon-check tick-icon" style="
                                          position: absolute;
                                          left: -10px;
                                          top: 5px;
                                          color: #fff;
                                          font-size: 10px;
                                        "></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="
                                  display: flex;
                                  justify-content: center;
                                  margin-top: 30px;
                                ">
                                <el-button style="min-width: 150px" @click="closePop(val)">取消</el-button>
                                <el-button style="min-width: 150px" type="primary"
                                  @click="editGuge(val, item, i, index)">确认</el-button>
                              </div>
                            </div>
                            <i style="cursor: pointer" slot="reference" class="el-icon-caret-bottom"></i>
                          </el-popover>
                        </div>

                        <div>{{ val.sku }}</div>
                      </span>
                    </div>
                  </div>
                  <div style="width: 15%; flex-wrap: wrap" class="item">
                    <span v-if="val.fprice" style="
                        text-decoration: line-through;
                        color: #b0b0b0;
                        margin-right: 20px;
                      ">RM{{ val.fprice }}</span>
                    RM{{ val.price }}
                  </div>
                  <div style="width: 14%" class="item">
                    <el-input-number v-model="val.num" @change="handleChanges($event, val, item)" :min="1"
                      :max="val.stock" label="描述文字" size="mini" style="width: 100px"></el-input-number>
                    <!-- {{val}} -->
                  </div>
                  <div v-if="val.discount_number != 0" style="width: 13%; color: #ee4d2d" class="item">
                    RM{{
                    val.num > val.discount_number
                    ? (
                    val.fprice * (val.num - val.discount_number) +
                    val.price * val.discount_number
                    ).toFixed(2)
                    : (val.price * val.num).toFixed(2)
                    }}
                  </div>
                  <div v-else style="width: 13%; color: #ee4d2d" class="item">
                    RM{{ val.price * val.num }}
                  </div>
                  <div style="width: 13%" class="item">
                    <span>
                      <div @click="sdelGoods(val, item, index, i)" style="
                          text-align: right;
                          padding-right: 20px;
                          margin-bottom: 2px;
                          cursor: pointer;
                        ">
                        删除
                      </div>
                      <div style="color: #a7a7a7; cursor: pointer" @click.stop="openXSGoods(val)">
                        找相似
                        <i class="el-icon-caret-bottom"></i>
                      </div>
                    </span>
                  </div>
                  <div class="goods-float" v-if="val.show">
                    <div style="display: flex; flex-wrap: wrap; padding: 20px 10px">
                      <BuyCartGoods :data="item" v-for="item in similarList.list" :key="item.id"></BuyCartGoods>
                    </div>
                    <div style="
                        display: flex;
                        justify-content: center;
                        padding: 20px 0;
                      ">
                      <el-pagination background layout="prev, pager, next" :total="similarList.total">
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </el-checkbox-group>

              <div style="
                  witdh: 100%;
                  height: 30px;
                  background: #fff;
                  color: #0055bc;
                  font-size: 12px;

                  padding: 0 20px;
                ">
                <span v-if="item.coupon_amount != ''" style="color: #ee4d2d; margin-right: 20px"><i class=""></i> 已使用{{
                  item.coupon_amount }}折扣</span>
                <el-popover placement="bottom" width="446" trigger="click" @show="openMore(item.goods_list[0].shop_id)"
                  v-model="item.show">
                  <div>
                    <div style="font-size: 14px; font-weight: 600">
                      {{ item.title + " 优惠券" }}
                    </div>
                    <!-- <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #f8f8f8;
                        padding: 10px;
                        margin-top: 10px;
                      "
                    >
                      <span>添加优惠券</span>
                      <el-input
                        style="max-width: 200px"
                        v-model="input"
                        size="medium"
                        placeholder="商店优惠券代码"
                      ></el-input>
                      <el-button size="medium" plain>使用</el-button>
                    </div> -->
                    <div style="font-size: 12px; padding: 10px 0">
                      已使用优惠券折扣
                    </div>
                    <div class="coupons-list" style="
                        padding-left: 10px;
                        max-height: 400px;
                        overflow: scroll;
                        overflow-x: hidden;
                      " v-if="couponList.length != 0">
                      <CouponsVII v-for="val in couponList" :key="val.id" :data="val" :title="item.title"
                        :tx="item.avatar"></CouponsVII>
                    </div>
                    <div v-else style="
                        text-align: center;
                        line-height: 50px;
                        color: #b0b0b0;
                      ">
                      暂无
                    </div>
                  </div>
                  <span slot="reference" style="cursor: pointer">
                    更多优惠券</span>
                </el-popover>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <div v-else>
          <el-empty :image="shopCart" description="您的购物车还是空的"></el-empty>
          <div style="display: flex; justify-content: center; margin-top: -20px">
            <el-button @click="toHome" type="primary" style="min-width: 140px">去血拼吧！</el-button>
          </div>
        </div>

        <div class="buyCart-bottom" v-if="data.length != 0">
          <div class="buyCart-bottom_l">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
              @change="handleCheckAllChange"></el-checkbox>
            <span style="margin: 0 20px">全选 ({{ xzCangdu }}) </span>
            <span @click="allDelGoods" style="cursor: pointer">删除</span>
          </div>
          <div class="buyCart-bottom_r">
            <div>
              <el-popover placement="top" title="折扣详情" width="500" trigger="hover">
                <div style="border-top: 1px solid #eee; font-size: 12px">
                  <div>
                    <div style="
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      ">
                      <div>商品小计</div>
                      <div>RM{{ goodsPrice }}</div>
                    </div>
                    <div style="
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      ">
                      <div>运费小计</div>
                      <div>RM{{ freight }}</div>
                    </div>
                  </div>
                  <div style="border-top: 1px solid #eee" v-if="
                      couponsPrice != 0 || discountPrice != 0 || suitPrice != 0
                    ">
                    <div v-if="couponsPrice != 0" style="
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      ">
                      <div>优惠券折扣</div>
                      <div>RM{{ couponsPrice }}</div>
                    </div>
                    <div v-if="discountPrice != 0" style="
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      ">
                      <div>商品折扣</div>
                      <div>RM{{ discountPrice }}</div>
                    </div>
                    <div v-if="suitPrice != 0" style="
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      ">
                      <div>套装优惠</div>
                      <div>RM{{ suitPrice }}</div>
                    </div>
                  </div>
                  <div style="border-top: 1px solid #eee">
                    <div v-if="savePrice != 0" style="
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      ">
                      <div>省了</div>
                      <div>RM{{ savePrice }}</div>
                    </div>
                    <div style="
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: -4px;
                      ">
                      <div>付款总额</div>
                      <div>RM{{ toalePrice }}</div>
                    </div>
                  </div>
                  <div style="color: #b0b0b0; text-align: right">
                    *结账处显示的最终价格
                  </div>
                </div>
                <div slot="reference" style="display: flex; align-items: center">
                  总额({{ xzCangdu }}个商品):
                  <span style="font-size: 20px; color: #ee4d2d">RM{{ toalePrice }}</span>
                  <i style="margin-left: 4px" class="el-icon-arrow-up"></i>
                </div>
              </el-popover>

              <div style="text-align: right; font-size: 12px; margin-top: 4px">
                省了
                <span style="color: #ee4d2d">RM{{ savePrice }}</span>
              </div>
            </div>
            <div>
              <el-button @click="toPay(false)" type="primary"
                style="min-width: 180px; margin-left: 20px">去买单</el-button>
            </div>
          </div>
        </div>
      </div>
      <div style="font-size: 16px; color: #777777; margin: 20px 0">
        猜你喜欢
      </div>
      <div style="display: flex; flex-wrap: wrap">
        <LikeGoods :data="item" v-for="(item, i) in likeList" :key="i"></LikeGoods>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    BuyCartList,
    delCartGoods,
    updateCart,
    updateCartNum,
    getSimilarGoods,
    likes,
  } from "@/api/home.js";
  import { getOrderGoodsList, getAllCoupon } from "@/api/buy.js";
  import BuyCartGoods from "@/components/goods/BuyCartGoods";
  import LikeGoods from "@/components/goods/LikeGoods";
  import CouponsVII from "@/components/marketing/CouponsVII";
  export default {
    components: {
      BuyCartGoods,
      LikeGoods,
      CouponsVII,
    },
    data() {
      return {
        checkAll: "",
        checkedCities: [],
        isIndeterminate: false,
        num: 1,
        data: [],
        toaleNum: 0,
        xzCangdu: 0,
        goodsPrice: "0.00", //商品小计
        freight: "0.00",
        couponsPrice: "0.00", //优惠券总折扣
        discountPrice: "0.00", //商品折扣
        suitPrice: "0.00",
        toalePrice: "0.00", //付款总额
        savePrice: "0.00", //省下的
        shopCart: require("@/assets/img/app/shoppingCart.png"),
        similarList: "",
        page: 1,
        pageSize: 6,
        likeList: "",
        input: "",
        suit_id: 0,
        couponList: "",
        index: undefined,
      };
    },
    created() {
      this.getBuyCart();
      this.getLikes();
    },
    methods: {
      // 获取购物车
      getBuyCart() {
        BuyCartList().then((res) => {
          if (res.code == 1) {
            this.data = res.data;
            this.toaleNum += this.data.length;
            // 添加选择框属性
            this.data.forEach((item) => {
              item.coupon_amount = "";
              item.show = false;
              item.isIndeterminate = false;
              item.xzList = [];
              this.toaleNum += item.goods_list.length;
              item.goods_list.forEach((val) => {
                val.fprice = "";
                if (val.discount_ratio != 0) {
                  val.fprice = val.price;
                  val.price = (
                    val.price *
                    (parseInt(val.discount_ratio) / 100)
                  ).toFixed(2);
                }
                if (val.suit_id != 0) {
                  this.suit_id = val.suit_id;
                }

                val.coupon_id = 0;
                val.show = false;
                // 保存旧商品数量
                val.oldNum = val.num;
                if (val.spu.length != 0) {
                  val.spu.forEach((eachs, i) => {
                    eachs.item = eachs.item.split(",");
                    eachs.item_img = eachs.item_img
                      ? eachs.item_img.split(",")
                      : "";
                    let data = val.sku.split(",")[i];
                    val.spu[i].item.indexOf(data);
                    eachs.spIndex = val.spu[i].item.indexOf(data);
                    eachs.oldSpIndex = val.spu[i].item.indexOf(data);
                  });

                  // 初始化规格图片
                  if (val.spu[0].item_img[0]) {
                    let text = val.sku.split(",")[0];
                    val.image =
                      val.spu[0].item_img[val.spu[0].item.indexOf(text)];
                  }
                }
              });
            });
            // this.data[1].goods_list[0].sku_list[3].now_price = "8.10";
          }
          // if (res.code == -1) {
          //   this.$router.replace("buyLogin");
          //   this.$store.state.buyLoginType = 2;
          // }
          if (res.code == -1 || res.code == 6000) {
            if (sessionStorage.BACKURL) {
              window.location.replace(sessionStorage.BACKURL)
            }
          }
        });
      },
      // 获取猜你喜欢
      getLikes() {
        likes().then((res) => {
          if (res.code == 1) {
            this.likeList = res.data;
          }
        });
      },
      // 顶部全选
      handleCheckAllChange(val) {
        let arr = [];
        this.data.forEach((item) => {
          if (val) {
            arr.push(item.title);
          }
          this.handleCheckAllChanges(val, item);
        });
        this.checkedCities = arr;
        this.isIndeterminate = false;
      },
      // 商店全选
      handleCheckAllChanges(val, data) {
        let arr = [];
        if (val) {
          data.goods_list.forEach((item) => {
            arr.push(item.id);
            // this.toalePrice = (
            //   parseFloat(this.toalePrice) +
            //   item.price * item.num
            // ).toFixed(2);
          });
        }
        // else {
        //   data.goods_list.forEach((item) => {
        //     this.toalePrice = (this.toalePrice - item.price * item.num).toFixed(
        //       2
        //     );
        //   });
        // }
        data.xzList = arr;
        data.isIndeterminate = false;
        let xzCangdu = 0;
        xzCangdu += this.checkedCities.length;
        this.data.forEach((item) => {
          xzCangdu += item.xzList.length;
        });

        if (this.toaleNum != xzCangdu) {
          if (xzCangdu == 0) {
            this.checkAll = false;
            this.isIndeterminate = false;
            this.handleZero();
          } else {
            this.isIndeterminate = true;
          }
        }
        this.xzCangdu = xzCangdu - this.checkedCities.length;

        if (val) {
          this.toPay(2);
        }
      },
      handleCheckedCitiesChange(val) {
        let checkedCount = val.length;
        this.checkAll = checkedCount === this.data.length;
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.data.length;
      },
      // 商品勾选
      handleChange(val, data, item) {
        if (val) {
          if (this.checkedCities.indexOf(data.title) == -1) {
            // 选框中添加店铺
            this.checkedCities.push(data.title);
          }
        }

        if (data.goods_list.length != data.xzList.length) {
          if (data.xzList.length == 0) {
            this.checkedCities.splice(this.checkedCities.indexOf(data.title), 1);
            data.isIndeterminate = false;
          } else {
            data.isIndeterminate = true;
          }
        } else {
          data.isIndeterminate = false;
        }

        let xzCangdu = 0;
        xzCangdu += this.checkedCities.length;
        this.data.forEach((item) => {
          xzCangdu += item.xzList.length;
        });

        if (this.toaleNum != xzCangdu) {
          if (xzCangdu == 0) {
            this.checkAll = false;
            this.isIndeterminate = false;
          } else {
            this.isIndeterminate = true;
          }
        }

        this.xzCangdu = xzCangdu - this.checkedCities.length;
        if (this.checkedCities.length == 0) {
          this.handleZero();
        } else {
          this.toPay(2);
        }
        this.$forceUpdate();
      },
      // 更改商品数量
      handleChanges(newVla, item, data) {
        updateCartNum({
          id: item.id,
          num: newVla,
        }).then((res) => {
          if (res.code == 1) {
            if (this.checkedCities.indexOf(item.shop_name) != -1) {
              this.toPay(2);
            }
          }
        });

        if (newVla > item.oldNum) {
          let num = newVla - item.oldNum;

          item.oldNum = item.num;
          if (data.xzList.indexOf(item.id) != -1) {
            this.toalePrice = (
              parseFloat(this.toalePrice) +
              num * item.price
            ).toFixed(2);
          }
        } else {
          let num = item.oldNum - newVla;
          item.oldNum = item.num;
          if (data.xzList.indexOf(item.id) != -1) {
            this.toalePrice = (
              parseFloat(this.toalePrice) -
              num * item.price
            ).toFixed(2);
          }
        }
        // this.$forceUpdate();
      },
      // 规格切换
      qiehuan(val, index) {
        val.oldSpIndex = val.spIndex;
        val.spIndex = index;
        this.$forceUpdate();
      },
      // 打开规格选项
      asds(val) {
        val.spu.forEach((item, i) => {
          val.spu[i].spIndex = val.spu[i].oldSpIndex;
        });
      },
      // 关闭规格
      closePop(val) {
        val.pop = false;
        this.$forceUpdate();
      },
      //修改规格
      editGuge(val, data, index, index2) {
        // 切换选中规格
        if (val.spu.length == 2) {
          val.sku =
            val.spu[0].item[val.spu[0].spIndex] +
            "," +
            val.spu[1].item[val.spu[1].spIndex];
        }
        if (val.spu.length == 1) {
          val.sku = val.spu[0].item[val.spu[0].spIndex];
        }

        // 切换选中的图片
        if (val.spu[0].item_img[0]) {
          val.image = val.spu[0].item_img[val.spu[0].spIndex];
        }

        // 切换价格(选中合计减去原价添加现价)
        updateCart({
          goods_id: val.goods_id,
          id: val.id,
          sku: val.sku,
          number: val.num,
          coupon_id: val.coupon_id,
          suit_id: val.suit_id,
          discount_id: val.discount_id,
          discount_type: val.discount_type,
        }).then((res) => {
          if (res.code == 1) {
            // 旧规格替换新规格
            val.spu.forEach((item) => {
              item.oldSpIndex = item.spIndex;
            });

            this.data[index].goods_list.forEach((item, i) => {
              if (item.id == val.id) {
                if (index2 != i) {
                  item.num = item.num + val.num;
                  this.data[index].goods_list.splice(index2, 1);
                }
              }
            });
          }
        });

        if (data.xzList.indexOf(val.id) != -1) {
          this.toalePrice = (
            parseFloat(this.toalePrice) -
            val.price * val.num
          ).toFixed(2);
        }
        val.sku_list.forEach((item) => {
          if (val.sku == item.difference) {
            if (item.discount_info) {
              val.fprice = item.now_price;
              val.price = (
                item.now_price *
                (parseInt(item.discount_info.discount_ratio) / 100)
              ).toFixed(2);
            } else {
              val.fprice = "";
              val.price = item.now_price;
            }
            val.id = item.id;
            val.stock = item.stock;
          }
        });
        if (data.xzList.indexOf(val.id) != -1) {
          this.toalePrice = (
            parseFloat(this.toalePrice) +
            val.price * val.num
          ).toFixed(2);
        }

        val.pop = false;
      },
      // 打开相似商品
      openXSGoods(val) {
        this.similarList = "";
        getSimilarGoods({
          category_id: val.category_id,
          page: this.page,
          page_size: this.pageSize,
        }).then((res) => {
          if (res.code == 1) {
            this.similarList = res.data;
          }
        });
        this.data.forEach((item) => {
          item.goods_list.forEach((val) => {
            val.show = false;
            val.pop = false;
          });
        });
        val.show = val.show ? false : true;
        this.$forceUpdate();
      },
      // 关闭相似商品
      closeXSGoods() {
        this.data.forEach((item) => {
          item.goods_list.forEach((val) => {
            val.show = false;
          });
        });
        this.$forceUpdate();
      },
      // 删除商品
      sdelGoods(val, item, index, i) {
        delCartGoods({
          id: val.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message);
            // 删除商品
            item.goods_list.splice(index, 1);
            // 判断是否选中(选中合计减去商品价格)
            if (item.xzList.indexOf(val.id) != -1) {
              // this.toalePrice = (
              //   parseFloat(this.toalePrice) -
              //   val.price * val.num
              // ).toFixed(2);
              this.toPay(true);
            }
            if (item.goods_list.length == 0) {
              this.data.splice(i, 1);
            }
          }
        });
      },
      // 全部删除
      allDelGoods() {
        let arr = [];

        this.data.forEach((item) => {
          arr = arr.concat(item.xzList);
        });
        delCartGoods({
          id: arr.join(","),
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message);
          }
          this.isIndeterminate = false;
          this.checkedCities = [];
          // 删除选中商品
          this.data.forEach((item) => {
            item.isIndeterminate = false;
            arr.forEach((val) => {
              item.goods_list.forEach((e, index) => {
                // 找出选中商品删除
                if (e.id === val) {
                  item.goods_list.splice(index, 1);
                  this.xzCangdu -= 1;
                  this.toalePrice = (
                    parseFloat(this.toalePrice) -
                    e.price * e.num
                  ).toFixed(2);
                }
              });
              item.xzList.forEach((e, index) => {
                if (e == val) {
                  item.xzList.splice(index, 1);
                }
              });
            });
          });

          // 选中商店购物车商品为空删除商店
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].goods_list.length === 0) {
              this.data.splice(i, 1);
              i--;
            }
          }

          this.$forceUpdate();
        });
      },
      // 跳转商品详情
      toGoodsInfo(id) {
        this.$router.replace({ path: "goodsInfo", query: { goodsId: id } });
      },
      // 去支付
      toPay(type, title, id) {
        if (this.xzCangdu <= 0) {
          this.$message.info("请购选要下单的商品");
          if (this.index != undefined) {
            this.data[this.index].show = false;
            this.$forceUpdate();
          }
          return false;
        }
        let arr = [];
        this.data.forEach((item) => {
          item.goods_list.forEach((val) => {
            if (item.xzList.indexOf(val.id) != -1) {
              arr.push({
                goods_id: val.goods_id,
                number: val.num,
                sku_id: val.id,
                coupon_id: item.cid,
                discount_id: val.discount_id,
                discount_type: val.discount_type,
                suit_id: val.suit_id,
              });
            }
          });
        });
        // 组装好的购物车列表
        sessionStorage.PAYGOODSLIST = JSON.stringify(arr);
        if (type) {
          getOrderGoodsList({
            data: arr,
          }).then((res) => {
            if (res.code == 1) {
              if (type == 1) {
                if (
                  res.data.orderData[this.checkedCities.indexOf(title)]
                    .coupon_price === 0
                ) {
                  this.$message.info("此优惠券不满足要求");
                  return false;
                } else {
                  this.data[this.index].show = false;
                  this.data[this.index].goods_list.forEach((item) => {
                    item.coupon_id = id;
                  });
                  this.couponList.forEach((item) => {
                    if (item.id == id) {
                      this.data[this.index].coupon_amount =
                        item.discount_coupon_type == 1
                          ? "RM" + item.discount_amount
                          : item.discount_amount + "%";
                      //  this.data[this.index].coupon_amount =""
                    }
                  });
                }
              }
              this.handleZero();
              res.data.orderData.forEach((item, i) => {
                // 1.多个店铺商品总额相加
                this.toalePrice = (
                  parseFloat(this.toalePrice) + parseFloat(item.sub_price)
                ).toFixed(2);
                //2.商品小计
                this.goodsPrice = (
                  parseFloat(this.goodsPrice) + parseFloat(item.sub_price)
                ).toFixed(2);
                this.freight = (
                  parseFloat(this.freight) + parseFloat(item.total_freight)
                ).toFixed(2);
                // 3.多个店铺运费相加
                this.toalePrice = (
                  parseFloat(this.toalePrice) + parseFloat(item.total_freight)
                ).toFixed(2);
                // 4.优惠券折扣
                this.couponsPrice = (
                  parseFloat(this.couponsPrice) + parseFloat(item.coupon_price)
                ).toFixed(2);

                // 5.商品折扣
                this.discountPrice = (
                  parseFloat(this.discountPrice) + parseFloat(item.discount_price)
                ).toFixed(2);
                // 5.套装优惠
                this.suitPrice = (
                  parseFloat(this.suitPrice) + parseFloat(item.suit_price)
                ).toFixed(2);
                // 6.扣除优惠券折扣
                this.toalePrice = (
                  parseFloat(this.toalePrice) - parseFloat(item.coupon_price)
                ).toFixed(2);
                // 7.扣除商品折扣
                this.toalePrice = (
                  parseFloat(this.toalePrice) - parseFloat(item.discount_price)
                ).toFixed(2);
                // 7.扣除套装优惠
                this.toalePrice = (
                  parseFloat(this.toalePrice) - parseFloat(item.suit_price)
                ).toFixed(2);
                // 8.省下
                this.savePrice = (
                  parseFloat(this.savePrice) +
                  parseFloat(item.coupon_price) +
                  parseFloat(item.discount_price) +
                  parseFloat(item.suit_price)
                ).toFixed(2);

                if (item.coupon_price === 0) {
                  this.data[i].coupon_amount = "";
                }
              });
            }
          });
        } else {
          window.scrollTo(0, 0);
          this.$router.push({ path: "buyPay", query: { type: 1 } });
        }
      },
      // 条状到首页
      toHome() {
        this.$router.replace("/");
      },
      // 使用优惠券
      closeShow(val, title) {
        if (this.xzCangdu <= 0) {
          this.$message.info("请购选要下单的商品");
          this.data.forEach((item, i) => {
            if (item.title == title) {
              this.data[i].show = false;
              // this.data[i].cid = val.id;
              // this.index = i;
            }
          });
          this.$forceUpdate();

          return false;
        }
        this.data.forEach((item, i) => {
          if (item.title == title) {
            this.index = i;
            this.data[i].show = false;
            this.data[i].cid = val.id;
            this.data[i].coupon_amount =
              val.discount_coupon_type == 1
                ? "RM" + val.discount_amount
                : val.discount_amount + "%";
            // this.$forceUpdate();
          }
        });
        this.toPay(2, title, val.id);
      },
      // 格式化
      handleZero() {
        this.goodsPrice = "0.00"; //商品小计
        this.freight = "0.00"; //运费小计
        this.couponsPrice = "0.00"; //优惠券总折扣
        this.discountPrice = "0.00"; //商品折扣
        this.suitPrice = "0.00"; //套装优惠
        this.toalePrice = "0.00"; //付款总额
        this.savePrice = "0.00"; //省下的
      },
      // 获取更多优惠券
      openMore(id) {
        getAllCoupon({
          shop_id: id,
        }).then((res) => {
          if (res.code == 1) {
            this.couponList = res.data;
          }
        });
      },
    },
  };
</script>
<style lang="less">
  .buyCard {
    background: #f5f5f5;
    padding: 20px 0;

    .tab {
      .tab-head {
        display: flex;
        text-align: center;
        font-size: 14px;
        background: #fff;
        padding: 20px 20px;
        border-radius: 4px;
        margin-bottom: 20px;
        box-shadow: 0 1px 4px 0 rgb(220 220 220 / 60%);
        color: #888;
      }

      .tab-list {
        border-bottom: 1px solid #eee;

        .item {
          background: #fff;
          margin-bottom: 20px;

          .title {
            padding: 20px 24px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
          }

          .content {
            padding: 20px 20px;
            display: flex;
            font-size: 14px;
            position: relative;

            .el-input-number__decrease,
            .el-input-number__increase {
              background: #fff;
            }

            .item {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .goods-float {
              position: absolute;
              width: 1200px;
              left: 0;
              top: 70px;
              // height: 300px;
              border: 1px solid #ee4d2d;
              background: #fff;
              z-index: 3;
            }
          }

          .guge {
            .guge-list {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }
          }
        }
      }

      .buyCart-bottom {
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
        font-size: 16px;

        .buyCart-bottom_l {
          display: flex;
          align-items: center;
        }

        .buyCart-bottom_r {
          display: flex;
          align-items: center;
        }
      }
    }
  }
</style>