<template>
  <div class="similarGoods">
    <div class="items">
      <div>
        <el-image
          style="width: 166px; height: 166px"
          :src="data.image"
          fit="fit"
        ></el-image>
      </div>
      <div style="padding: 0 10px">
        <div class="titles">
          {{ data.title }}
        </div>
        <div style="margin-top: 8px">
          <el-tag size="mini" effect="plain" style="margin-right: 4px"
            >local Seller</el-tag
          >
          <el-tag size="mini" effect="plain" style="margin-right: 4px"
            >COD</el-tag
          >
        </div>
        <div style="margin-top: 8px; font-size: 14px; color: #ee4d2d">
          {{ data.price_rm }}
        </div>
        <div
          style="display: flex; justify-content: space-between; margin-top: 8px"
        >
          <div>
            <svg
              t="1654480584359"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2203"
              width="16"
              height="16"
            >
              <path
                d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z m0 63.146667c-41.44 0-70.261333 15.189333-116.96 55.04-2.165333 1.845333-14.4 12.373333-17.941334 15.381333a32.32 32.32 0 0 1-41.770666 0c-3.541333-3.018667-15.776-13.536-17.941334-15.381333-46.698667-39.850667-75.52-55.04-116.96-55.04C230.186667 180.48 149.333333 281.258667 149.333333 426.698667 149.333333 537.6 262.858667 675.242667 493.632 834.826667a32.352 32.352 0 0 0 36.736 0C761.141333 675.253333 874.666667 537.6 874.666667 426.698667c0-145.44-80.853333-246.218667-206.88-246.218667z"
                p-id="2204"
                fill="#8a8a8a"
              ></path>
            </svg>
          </div>
          <div style="display: flex">
            <el-rate v-model="value1" disabled></el-rate>
            <span style="margin-left: 6px">售{{data.sales}}</span>
          </div>
        </div>
        <div style="text-align: right; margin-top: 4px">中国大陆</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      value1: 5,
    };
  },
};
</script>
<style lang="less">
.similarGoods {
  width: 166px;
  margin: 0 14px;
  font-size: 12px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding-bottom: 6px;
  .titles {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
  }
  .el-rate__icon {
    font-size: 12px;
    margin-right: -4px;
  }
}
</style>