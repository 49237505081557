<template>
  <div class="CouponsVII">
    <div class="ban" v-if="type == 'end'"></div>
    <div class="btn">
      <!-- 使用<i class="el-icon-arrow-right"></i> -->

      <el-button
        v-if="!data.receiveCoupon"
        @click="handleLq"
        size="small"
        type="primary"
        >领取</el-button
      >
      <el-button @click="handleUse" v-else size="small" plain>使用</el-button>
    </div>
    <div class="folat">
      <div class="asd"></div>
    </div>
    <div class="j-left">
      <div>
        <el-avatar
          style="background: #fff; border: 1px solid #eee"
          :size="60"
          :src="tx"
        ></el-avatar>
      </div>
    </div>
    <div class="line"></div>
    <div class="j-right">
      <div style="font-size: 14px; font-weight: 600">
        {{
          data.discount_coupon_type == 1
            ? "RM" + data.discount_amount
            : data.discount_amount + "%"
        }}折扣
      </div>
      <div style="font-size: 12px; width: 140px">
        最低消费RM{{ data.min_consume_amount
        }}<span v-if="data.max_discount_amount != 0"
          >,上限为RM{{ data.max_discount_amount }}</span
        >
      </div>
      <div
        style="margin: 6px 0; transform: scale(0.9)"
        v-if="data.couponGoods.length != 0"
      >
        <span class="tags">指定商品</span>
      </div>
      <!-- <div class="seep">
        <div class="tiao"></div>
      </div>
      <div style="font-size: 12px; color: #ee4d2d">
        已使用78%,即将截止：剩下6小时
      </div> -->
      <div
        style="font-size: 12px; color: #afaaa2; margin-top: 4px"
        v-if="data.end_time * 1000 - dqTime >= time24"
      >
        有效期：{{ data.end_time | formatTime("djs") }}
      </div>
      <div
      v-else
        style="font-size: 12px; color: #afaaa2; margin-top: 4px; color: #ee4d2d"
      >
        即将截止：剩下{{ data.end_time | formatTime("djs") }}
      </div>
    </div>
  </div>
</template>
<script>
import { couponReceive } from "@/api/buy.js";
import { getBAuth } from "@/utils/auth.js";
export default {
  name: "CouponsVII",
  props: ["type", "data", "infoData", "title", "tx"],
  data() {
    return {
      circleUrl: "",
      time24: 24 * 60 * 60 * 1000,
      dqTime: Date.now(),
    };
  },
  methods: {
    handleLq() {
      if (!getBAuth()) {
        this.$router.push("buyLogin");
        this.$store.state.buyLoginType = 2;
        return false;
      }
      couponReceive({
        keyword: this.$props.data.id,
        type: 1,
      }).then((res) => {
        if (res.code == 1) {
          this.$props.data.receiveCoupon = {
            coupon_id: this.$props.data.id,
            state: 1,
          };
          this.$forceUpdate();
          this.$message.success(res.message);
        }
      });
    },
    handleUse() {

      this.$parent.$parent.$parent.closeShow(
        this.$props.data,
        this.$props.title
      );
    },
  },
};
</script>
<style lang="less" scoped>
.CouponsVII {
  width: 390px;
  height: 120px;
  border: 1px solid #eee;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  border-left: 1px solid rgba(250, 250, 250, 0.01);
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-right: 20px;
  margin-bottom: 20px;
  .btn {
    position: absolute;
    right: 10px;
    top: 32px;
    font-size: 12px;
    color: #ee4d2d;
    cursor: pointer;
  }
  .folat {
    content: "";
    width: 6px;
    height: 100%;
    position: absolute;
    left: -1px;
    top: 0;
    background: radial-gradient(
      circle at 0 6px,
      transparent 0,
      #fff 5px,
      #eee 0,
      #eee 6px,
      #fff 0
    );
    background-size: 6px 14px;
    background-repeat: repeat-y;
    .asd {
      background: repeating-linear-gradient(
        #eee,
        #eee 1.9px,
        transparent 0,
        transparent 12px
      );
      background-size: 6px 14px;
      position: absolute;
      left: 0;
      top: -1px;
      height: 100%;
      width: 1px;
    }
  }
  .j-left {
    width: 120px;
    height: 100%;
    text-align: center;
    padding: 20px;
  }
  .j-right {
    width: 26 0px;
    .tags {
      border: 1px solid #ee4d2d;
      padding: 2px;
      font-size: 12px;
      border-radius: 2px;
      color: #ee4d2d;
      margin-left: -10px;
    }
    .seep {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: #e8e8e8;
      margin-bottom: 4px;
      .tiao {
        width: 70%;
        height: 100%;
        background: linear-gradient(to right, #eb1d16, #feac00);
        border-radius: 4px;
      }
    }
  }
  .line {
    height: 100px;
    width: 1px;
    margin-right: 10px;
    background-image: linear-gradient(
      to bottom,
      #eee 0%,
      #eee 50%,
      transparent 0%
    );
    background-size: 1px 8px;
    background-repeat: repeat-y;
  }
  .ban {
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 22;
    opacity: 0.7;
  }
}
</style>